<template>
  <div class="case">
    <div style="width: 100%; padding-top: 90px; padding-bottom: 45px;">
      <div class="case-title">
        <img src="~@a/images/case-images/cpal.png" alt="" />
      </div>
    </div>
    <!-- 内容区 -->
    <div style="width: 100%; padding-top: 45px; background-color: #fafafa;">
      <div class="case-container">
        <!-- 左侧导航 -->
        <div>
          <div class="case-container-left">
            <div
              class="case-nav"
              v-for="(item, index) in casesNav"
              :key="item.id"
              :class="{ active: active == index ? 'active' : '' }"
            >
              <div class="point">
                <div class="point-dot"></div>
                <div
                  class="point-line"
                  v-if="index != casesNav.length - 1"
                ></div>
              </div>
              <div class="point-nav">
                <div class="point-nav-box"></div>
                <div class="point-nav-title" @click="casePointClick(index)">
                  {{ item.categoryName }}
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- 右侧内容 -->
        <div class="case-container-right">
          <!-- 智慧园区 -->
          <template v-if="casesList">
            <div
              style="
                width: 100%;
                background-color: #fff;
                padding-top: 30px;
                padding-bottom: 60px;
                margin-bottom: 40px;
              "
              v-for="(item, index) in casesList"
              :key="item.id"
              :id="'case' + index"
            >
              <div class="case-wisdom">
                <div class="case-wisdom-title">
                  {{ item.articles[0].categoryName }}
                </div>
                <div class="case-wisdom-line"></div>
              </div>
              <div class="case-wisdom-content">
                <div class="case-wisdom-content-title">
                  {{ item.articles[0].title }}
                </div>
                <div
                  class="case-wisdom-content-desc"
                  v-html="item.articles[0].content"
                ></div>
                <div
                  class="case-wisdom-content-img"
                  v-if="item.articles[0].imagePath"
                >
                  <img :src="item.articles[0].imagePath" alt="" />
                </div>
                <div class="case-wisdom-iconList">
                  <div
                    class="case-wisdom-icon"
                    v-for="it in item.children"
                    :key="it.id"
                    @click="
                      toPageInfo(
                        item.articles[0].categoryName,
                        it.url,
                        it.id,
                        it.typeCode,
                      )
                    "
                    :class="[
                      { smart: item.typeCode == 'smart_city' ? 'smart' : '' },
                      {
                        government:
                          item.typeCode == 'government' ? 'government' : '',
                      },
                    ]"
                  >
                    <div>
                      <img :src="it.imagePath" alt="" />
                      <div class="txt">
                        <div
                          class="case-wisdom-icon-title"
                          v-for="item in it.name.split(' ')"
                          :key="item"
                        >
                          {{ item }}
                        </div>
                      </div>
                      <div class="views">
                        <div class="title">案例详情</div>
                        <img src="~@a/images/case-images/jiantou.png" alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { GetCases } from '@/api/caseApi.js'

export default {
  data() {
    return {
      active: 0,
      casesList: [],
      casesNav: [], //导航
      bool: true,
    }
  },
  created() {
    // GetCases().then((res) => {
    //   if (res.code === 0) {
    //     this.casesList = res.result
    //     this.casesNav = this.casesList.map((item) => {
    //       return item.articles[0]
    //     })
    //     // console.log('this.casesList', this.casesList)
    //   }
    // })
    this.init()
  },
  mounted() {
    $(window).resize(() => {
      let h = $(window).height()
      if (h <= 712) {
        this.bool = false
        $('.case-container-left').css('position', 'absolute')
        $('.case-container-left').css('top', 'unset')
        $('.case-container-left').css('bottom', '120px')
      } else {
        this.bool = true
      }
    })
    $(window).scroll(() => {
      var scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop
      if (!this.bool) {
        return
      }
      $('.case-container-left').css('bottom', 'unset')

      if (scrollTop >= 940) {
        $('.case-container-left').css('position', 'fixed')
        $('.case-container-left').css('top', '20px')
      } else if (scrollTop < 940) {
        $('.case-container-left').css('position', 'relative')
      }
    })
  },
  watch: {
    $route() {
      this.casePointClick(this.$route.query.index)
    },
  },

  updated() {
    $('.case-wisdom-content-desc').find('p').css('fontSize', '18px')
    $('.case-wisdom-content-desc').find('p').css('lineHeight', '2')
    $('.case-wisdom-content-desc')
      .find('p')
      .css('fontFamily', 'Microsoft YaHei')
    $('.case-wisdom-content-desc')
      .find('span')
      .css('fontFamily', 'Microsoft YaHei')
    $('.case-wisdom-content-desc').find('span').css('fontSize', '18px')
    $('.case-wisdom-content-desc').find('span').css('lineHeight', '2')
    $('.case-wisdom-content-desc').find('p').css('color', '#666')
    $('.case-wisdom-content-desc').find('span').css('color', '#666')
  },
  methods: {
    init() {
      GetCases().then((res) => {
        if (res.code === 0) {
          this.casesList = res.result
          this.casesNav = this.casesList.map((item) => {
            return item.articles[0]
          })
          this.$nextTick(() => {
            this.casePointClick(this.$route.query.index)
          })
        }
      })
    },
    casePointClick(index) {
      if (index == 0) {
        this.active = 0
        $('html, body').animate(
          {
            scrollTop: $(`#case0`).offset().top,
          },
          { duration: 500, easing: 'swing' },
        )
        return
      }
      this.active = index ? index : 0
      if (index) {
        $('html, body').animate(
          {
            scrollTop: $(`#case${index}`).offset().top,
          },
          { duration: 500, easing: 'swing' },
        )
        return
      }
    },
    toPageInfo(categoryName, url, id, typeCode) {
      this.$router.push({
        path: '/bigTower/case/detail',
        query: {
          type: url,
          code: typeCode,
          id: id,
          categoryName: categoryName,
        },
      })
    },
  },
  components: {},
}
</script>
<style lang="less" scoped>
.case {
  div {
    .case-title {
      width: 1200px;
      height: 93px;
      margin: 0 auto;
      > img {
        width: 100%;
        height: 100%;
      }
    }

    .case-container {
      width: 1200px;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      position: relative;
      .case-container-left {
        margin-top: 40px;
        .case-nav {
          display: flex;
          &.active {
            .point {
              .point-dot {
                background-color: #e35513;
              }
            }
            .point-nav {
              .point-nav-title {
                color: #fff;
                // >a {
                //   color: #fff;
                // }
              }
            }
          }
          .point {
            display: flex;
            flex-direction: column;
            .point-dot {
              width: 21px;
              height: 21px;
              background-color: #fff;
              border: 1px solid #e35513;
              border-radius: 50%;
            }
            .point-line {
              width: 1px;
              height: 39px;
              margin-left: 10px;
              background-color: #e35513;
            }
          }
          &.active {
            .point-nav {
              .point-nav-box {
                background-color: #e35513;
              }
            }
          }
          .point-nav {
            width: 141px;
            height: 41px;
            margin-left: 10px;
            margin-top: -10px;
            position: relative;

            .point-nav-box {
              width: 141px;
              height: 41px;
              border-radius: 5px;
              border: 1px solid #e35513;
            }
            .point-nav-title {
              position: absolute;
              width: 100%;
              text-align: center;
              top: 50%;
              transform: translateY(-50%);
              font-size: 18px;
              font-family: MicrosoftYaHei;
              line-height: 41px;
              color: #e35513;
              cursor: pointer;
              // > a {
              //   display: block;
              //   width: 100%;
              //   height: 100%;
              //   &:hover {
              //     color: #000;
              //   }
              // }
            }
          }
        }
      }
      .case-container-right {
        width: 980px;
        div {
          position: relative;
          /* 智慧园区 */
          .case-wisdom {
            width: 100%;

            .case-wisdom-title {
              width: 900px;
              margin: 0 auto;
              height: 46px;
              font-size: 30px;
              font-family: MicrosoftYaHei-Bold;
              line-height: 46px;
              color: #333333;
              font-weight: 600;
            }
            .case-wisdom-line {
              width: 900px;
              margin: 0 auto;
              height: 8px;
              background-color: #ec9000;
            }
          }
          .case-wisdom-content {
            width: 100%;
            margin-top: 36px;
            .case-wisdom-content-title {
              width: 900px;
              margin: 0 auto;
              font-size: 22px;
              font-family: MicrosoftYaHei-Bold;
              line-height: 36px;
              color: #666666;
              font-weight: 600;
            }
            .case-wisdom-content-desc {
              width: 900px;
              //   height: 168px;
              margin: 12px auto 0 auto;
              font-size: 18px;
              font-family: MicrosoftYaHei;
              line-height: 36px;
              color: #666666;
            }
            .case-wisdom-content-img {
              width: 900px;
              height: 384px;
              margin: 17px auto 0 auto;
              > img {
                width: 100%;
                height: 100%;
                object-fit: contain;
              }
            }
            .case-wisdom-iconList {
              width: 900px;
              margin: 20px auto 0 auto;
              display: flex;
              flex-wrap: wrap;
              justify-content: space-between;
              .case-wisdom-icon {
                width: 160px;
                height: 160px;
                background-color: #f6f6f6;
                border-radius: 5px;
                position: relative;
                margin-bottom: 20px;
                cursor: pointer;
                .views {
                  display: none;
                  // display: flex;
                  // flex-direction: column;
                  > img {
                    width: 30px;
                    height: 8px;
                  }
                }
                &:hover {
                  background: rgba(0, 0, 0, 0.5);
                  transition: 500ms;
                  .views {
                    position: absolute;
                    top: 0;
                    right: 0;
                    left: 0;
                    bottom: 0;
                    display: flex;
                    flex-direction: column;
                    color: #fff;
                    .title {
                      padding: 60px 0 20px;
                      font-size: 18px;
                      line-height: 21px;
                    }
                    img {
                      display: block;
                      margin: 0 auto;
                      width: 30px;
                      height: 8px;
                    }
                  }
                }
                &.smart {
                  width: 280px;
                  height: 160px;
                }
                &.government {
                  width: 200px;
                }
                & > div {
                  width: 100%;
                  text-align: center;
                  padding-top: 30px;
                  > img {
                    width: 60px;
                    height: 60px;
                  }
                  & > div.txt {
                    width: 100%;
                    height: 50px;
                    text-align: center;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    .case-wisdom-icon-title {
                      text-align: center;
                      width: 100%;
                      font-size: 16px;
                      font-family: MicrosoftYaHei;
                      line-height: 22px;
                      color: #666666;
                      &:nth-of-type(2) {
                        // top: 93%;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
