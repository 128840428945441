var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"case"},[_vm._m(0),_c('div',{staticStyle:{"width":"100%","padding-top":"45px","background-color":"#fafafa"}},[_c('div',{staticClass:"case-container"},[_c('div',[_c('div',{staticClass:"case-container-left"},_vm._l((_vm.casesNav),function(item,index){return _c('div',{key:item.id,staticClass:"case-nav",class:{ active: _vm.active == index ? 'active' : '' }},[_c('div',{staticClass:"point"},[_c('div',{staticClass:"point-dot"}),(index != _vm.casesNav.length - 1)?_c('div',{staticClass:"point-line"}):_vm._e()]),_c('div',{staticClass:"point-nav"},[_c('div',{staticClass:"point-nav-box"}),_c('div',{staticClass:"point-nav-title",on:{"click":function($event){return _vm.casePointClick(index)}}},[_vm._v(" "+_vm._s(item.categoryName)+" ")])])])}),0)]),_c('div',{staticClass:"case-container-right"},[(_vm.casesList)?_vm._l((_vm.casesList),function(item,index){return _c('div',{key:item.id,staticStyle:{"width":"100%","background-color":"#fff","padding-top":"30px","padding-bottom":"60px","margin-bottom":"40px"},attrs:{"id":'case' + index}},[_c('div',{staticClass:"case-wisdom"},[_c('div',{staticClass:"case-wisdom-title"},[_vm._v(" "+_vm._s(item.articles[0].categoryName)+" ")]),_c('div',{staticClass:"case-wisdom-line"})]),_c('div',{staticClass:"case-wisdom-content"},[_c('div',{staticClass:"case-wisdom-content-title"},[_vm._v(" "+_vm._s(item.articles[0].title)+" ")]),_c('div',{staticClass:"case-wisdom-content-desc",domProps:{"innerHTML":_vm._s(item.articles[0].content)}}),(item.articles[0].imagePath)?_c('div',{staticClass:"case-wisdom-content-img"},[_c('img',{attrs:{"src":item.articles[0].imagePath,"alt":""}})]):_vm._e(),_c('div',{staticClass:"case-wisdom-iconList"},_vm._l((item.children),function(it){return _c('div',{key:it.id,staticClass:"case-wisdom-icon",class:[
                    { smart: item.typeCode == 'smart_city' ? 'smart' : '' },
                    {
                      government:
                        item.typeCode == 'government' ? 'government' : '',
                    },
                  ],on:{"click":function($event){return _vm.toPageInfo(
                      item.articles[0].categoryName,
                      it.url,
                      it.id,
                      it.typeCode,
                    )}}},[_c('div',[_c('img',{attrs:{"src":it.imagePath,"alt":""}}),_c('div',{staticClass:"txt"},_vm._l((it.name.split(' ')),function(item){return _c('div',{key:item,staticClass:"case-wisdom-icon-title"},[_vm._v(" "+_vm._s(item)+" ")])}),0),_vm._m(1,true)])])}),0)])])}):_vm._e()],2)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"width":"100%","padding-top":"90px","padding-bottom":"45px"}},[_c('div',{staticClass:"case-title"},[_c('img',{attrs:{"src":require("@a/images/case-images/cpal.png"),"alt":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"views"},[_c('div',{staticClass:"title"},[_vm._v("案例详情")]),_c('img',{attrs:{"src":require("@a/images/case-images/jiantou.png"),"alt":""}})])
}]

export { render, staticRenderFns }