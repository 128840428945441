import axios from "./http";

//案例 详情页
export function GetCases() {
    return axios({
        url: "/datalyg/view/cases",
        method: "get"
    })
}

